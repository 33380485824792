export const palette = {
  error: {
    main: '#f44336',
  },
  warning: {
    main: '#FF980E',
  },
  success: {
    main: '#20a30f',
  },
};
