import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';

export const useEntityData = (kind: string, name: string) => {
  const catalogApi = useApi(catalogApiRef);

  const { value } = useAsync(
    async () => await catalogApi.getEntityByRef(`${kind}:${name}`),
    [catalogApi, kind, name],
  );

  return value;
};
