import { ApiEntity, Entity } from '@backstage/catalog-model';
import { TableColumn } from '@backstage/core-components';
import { EntityTable } from '@backstage/plugin-catalog-react';

export const apiEntityHelpLink: string =
  'https://backstage.io/docs/features/software-catalog/descriptor-format#kind-api';

export const asAPIEntities = (entities: Entity[]): ApiEntity[] =>
  entities as ApiEntity[];

export const apiEntityColumns: TableColumn<ApiEntity>[] = [
  EntityTable.columns.createEntityRefColumn({ defaultKind: 'API' }),
  EntityTable.columns.createOwnerColumn(),
  EntityTable.columns.createSpecTypeColumn(),
  EntityTable.columns.createSpecLifecycleColumn(),
  EntityTable.columns.createSystemColumn(),
  EntityTable.columns.createMetadataDescriptionColumn(),
];
