import { pageTheme } from '@backstage/theme';

const themes = Object.keys(pageTheme);
/**
 * Available themes:
 * 0 : "home"
 * 1 : "documentation"
 * 2 : "tool"
 * 3 : "service"
 * 4 : "website"
 * 5 : "library"
 * 6 : "other"
 * 7 : "app"
 * 8 : "apis"
 * 9 : "card"
 */

export function mapTypeToTheme(type: string) {
  if (type.includes('Backstage') && type.includes('Techdocs')) {
    return themes[1];
  }
  if (type.includes('Backstage') && type.includes('Groups')) {
    return themes[2];
  }

  if (type.includes('Backstage') && type.includes('Component')) {
    return themes[3];
  }

  if (type.includes('Backstage') && type.includes('Resource')) {
    return themes[4];
  }

  if (type.includes('Backstage') && type.includes('System')) {
    return themes[5];
  }

  if (type.includes('Github') && type.includes('Template')) {
    return themes[7];
  }

  if (type.includes('Backstage') && type.includes('API')) {
    return themes[8];
  }

  return themes[6];
}
