import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { EntityAdrContent } from '@backstage/plugin-adr';
import { isAdrAvailable } from '@backstage/plugin-adr-common';
import {
  EntityProvidingComponentsCard,
  EntityConsumingComponentsCard,
  EntityApiDefinitionCard,
} from '@backstage/plugin-api-docs';
import { EntityLayout, EntitySwitch } from '@backstage/plugin-catalog';
import {
  EntityCatalogGraphCard,
  Direction,
} from '@backstage/plugin-catalog-graph';
import { EntitySonarQubeCard } from '@backstage/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage/plugin-sonarqube-react';
import { EntityLinksCard } from '@celonis/backstage-plugin-catalog';
import { Grid } from '@material-ui/core';
import React from 'react';

import { ciCdContent } from './EntityContentTabs';
import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import { EntityWarnings } from './EntityWarnings';
import { getFlag } from '../../../helpers/helpers';
import { GraphNode } from '../../catalogGraph/CatalogGraphNode';
import { ScoreCard } from '../../techInsights/Scorecard';
import { AboutCard as EntityAboutCard } from '../AboutCard/AboutCard';
import { adrContentDecorator } from '../adrContentDecorator';
import { EntityAudienceCard } from '../AudienceCard/AudienceCard';

export const ApiPage = () => {
  const config = useApi(configApiRef);

  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          <EntityWarnings />
          <Grid item md={6}>
            <EntityAboutCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard
              variant="gridItem"
              height={200}
              renderNode={GraphNode}
              direction={Direction.LEFT_RIGHT}
              unidirectional
              maxDepth={1}
            />
          </Grid>

          <Grid item md={6}>
            <EntityAudienceCard />
          </Grid>

          <Grid item md={6}>
            <EntityLinksCard variant="gridItem" />
          </Grid>

          <EntitySwitch>
            <EntitySwitch.Case
              if={e => isSonarQubeAvailable(e) && getFlag(config, 'sonarqube')}
            >
              <Grid item md={6}>
                <EntitySonarQubeCard variant="gridItem" />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>

          <Grid container item md={12}>
            <Grid item md={6}>
              <EntityProvidingComponentsCard />
            </Grid>
            <Grid item md={6}>
              <EntityConsumingComponentsCard />
            </Grid>
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/scorecards"
        title="Scorecards"
        if={() => getFlag(config, 'tech-insights')}
      >
        <ScoreCard />
      </EntityLayout.Route>
      <EntityLayout.Route
        path="/adrs"
        title="ADRs"
        if={entity => isAdrAvailable(entity) && getFlag(config, 'adr')}
      >
        <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
      </EntityLayout.Route>

      <EntityLayout.Route path="/github-actions" title="Github Actions">
        {ciCdContent}
      </EntityLayout.Route>

      <EntityLayout.Route path="/definition" title="Definition">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EntityApiDefinitionCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayoutWrapper>
  );
};
