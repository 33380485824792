import { DEFAULT_NAMESPACE, Entity } from '@backstage/catalog-model';
import { InfoCard, InfoCardVariants, Table } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { isNil } from 'lodash';
import React from 'react';

import {
  LINK_TO_CATALOG_DOCS,
  NON_INDEPENDENT_REALM_TYPES,
  NOT_DEPLOYED_IN_REALMS_MESSAGE,
} from './constants';
import {
  GithubData,
  ArgoCdData,
  CfgIBCData,
  DatadogData,
  LifecycleData,
  NameData,
  OwnerData,
  TierData,
  FedRAMP,
} from './TableItems';
import { NotApplicable } from './TableItems/Common';

export interface ServiceAlignmentCardProps {
  variant?: InfoCardVariants;
}

export interface DataFormat {
  application: string;
  value: React.JSX.Element;
}

function buildData(entity: Entity): DataFormat[] {
  const entityName = entity.metadata.name;
  const entityRef = `${entity.kind}:${
    entity.metadata.namespace ?? DEFAULT_NAMESPACE
  }/${entityName}`;
  const annotations = entity.metadata.annotations ?? {};
  const relations = entity.relations ?? [];

  const owners = relations
    .filter(relation => relation.type === 'ownedBy')
    .map(relation => relation.targetRef);

  const lifecycle = isNil(entity.spec)
    ? undefined
    : (entity.spec.lifecycle as string);
  const tier = isNil(entity.spec) ? undefined : (entity.spec.tier as string);

  const isNonRealmDeployable = NON_INDEPENDENT_REALM_TYPES.includes(
    (entity.spec?.type as string) ?? '',
  );

  return [
    {
      application: 'Name',
      value: <NameData entityRef={entityRef} />,
    },
    {
      application: 'Owner',
      value: <OwnerData owners={owners} />,
    },
    {
      application: 'Lifecycle',
      value: <LifecycleData lifecycle={lifecycle} />,
    },
    {
      application: 'Tier',
      value: isNonRealmDeployable ? (
        <NotApplicable tooltipText={NOT_DEPLOYED_IN_REALMS_MESSAGE} />
      ) : (
        <TierData tier={tier} lifecycle={lifecycle} />
      ),
    },
    {
      application: 'Github Repository',
      value: <GithubData annotations={annotations} />,
    },
    {
      application: 'ArgoCD App',
      value: isNonRealmDeployable ? (
        <NotApplicable tooltipText={NOT_DEPLOYED_IN_REALMS_MESSAGE} />
      ) : (
        <ArgoCdData annotations={annotations} />
      ),
    },
    {
      application: 'Folder in cfg-ibc',
      value: isNonRealmDeployable ? (
        <NotApplicable tooltipText={NOT_DEPLOYED_IN_REALMS_MESSAGE} />
      ) : (
        <CfgIBCData annotations={annotations} />
      ),
    },
    {
      application: 'Datadog Service',
      value: <DatadogData annotations={annotations} entityName={entityName} />,
    },
    {
      application: 'FedRAMP',
      value: <FedRAMP annotations={annotations} />,
    },
  ];
}

export function ServiceAlignmentCard(
  props: Readonly<ServiceAlignmentCardProps>,
) {
  const { variant = 'gridItem' } = props;
  const { entity } = useEntity();

  const deepLink = {
    title: 'Learn more about service alignment',
    link: LINK_TO_CATALOG_DOCS,
  };

  const columns = [
    { title: 'Service Parameter', field: 'application' },
    { title: 'Value', field: 'value' },
  ];
  const data = buildData(entity);

  return (
    <InfoCard
      title="Service Alignment"
      subheader="Service parameters and associated values"
      noPadding
      variant={variant}
      deepLink={deepLink}
    >
      <Table
        options={{
          search: false,
          paging: false,
          padding: 'dense',
          toolbar: false,
        }}
        columns={columns}
        data={data}
      />
    </InfoCard>
  );
}
