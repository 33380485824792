import { Entity, UserEntity } from '@backstage/catalog-model';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { isNil } from 'lodash';

function basicTitle(
  id: string,
  title?: string,
  kind?: string,
  name?: string,
  namespace?: string,
): string {
  return (
    title ??
    (kind && name && namespace
      ? humanizeEntityRef({ kind, name, namespace })
      : id)
  );
}

function userTitle(title: string, entity: UserEntity): string {
  if (isNil(entity) || isNil(entity.metadata.tags)) return `${title} - N/A`;

  const jobFamily = entity.metadata.tags[0];
  switch (jobFamily) {
    case 'engineering':
      return `${title} - ENG`;
    case 'product':
      return `${title} - PRD`;
    case 'qa':
      return `${title} - QA`;
    default:
      return `${title} - N/A`;
  }
}

export function getNodeText(
  id: string,
  title?: string,
  kind?: string,
  name?: string,
  namespace?: string,
  entity?: Entity,
): string {
  const displayTitle = basicTitle(id, title, kind, name, namespace);
  if (isNil(kind)) return displayTitle;

  switch (kind.toLowerCase()) {
    case 'user':
      return userTitle(displayTitle, entity as UserEntity);
    default:
      return displayTitle;
  }
}
