import {
  EntityHasSubcomponentsCard,
  EntityLayout,
  EntitySwitch,
  isComponentType,
} from '@backstage/plugin-catalog';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import { EntitySonarQubeCard } from '@backstage/plugin-sonarqube';
import { isSonarQubeAvailable } from '@backstage/plugin-sonarqube-react';
import { NewAnnouncementBanner } from '@celonis/backstage-plugin-announcements';
import { EntityLinksCard } from '@celonis/backstage-plugin-catalog';
import { Grid } from '@material-ui/core';
import React from 'react';

import { GraphNode } from '../../../catalogGraph/CatalogGraphNode';
import { AboutCard as EntityAboutCard } from '../../AboutCard/AboutCard';
import { ExtraContentTiles } from '../../ExtraContentTiles/ExtraContentTiles';
import { ServiceAlignmentCard } from '../../ServiceAlignmentCard/ServiceAlignmentCard';
import { EntityWarnings } from '../EntityWarnings';

interface OverviewContentParams {
  announcementEnabled: boolean;
}

export const overviewContent = ({
  announcementEnabled,
}: OverviewContentParams) => {
  return (
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <EntityWarnings />

        {announcementEnabled && (
          <Grid item md={12}>
            <NewAnnouncementBanner />
          </Grid>
        )}

        <Grid item md={4}>
          <EntityAboutCard variant="gridItem" />
        </Grid>

        <Grid item md={4} xs={12}>
          <EntityLinksCard variant="gridItem" />
        </Grid>

        <EntitySwitch>
          <EntitySwitch.Case if={isComponentType(['service', 'frontend'])}>
            <Grid item md={4} xs={12}>
              <ServiceAlignmentCard variant="gridItem" />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case
            if={entity => !isComponentType(['platform-service'])(entity)}
          >
            <Grid item md={8} xs={12}>
              <EntityCatalogGraphCard
                variant="gridItem"
                height={400}
                renderNode={GraphNode}
                direction={Direction.LEFT_RIGHT}
                unidirectional
                maxDepth={1}
              />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={entity => isSonarQubeAvailable(entity)}>
            <Grid item md={4}>
              <EntitySonarQubeCard variant="gridItem" />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case
            if={entity => !isComponentType(['platform-service'])(entity)}
          >
            <Grid item md={12} xs={12}>
              <EntityHasSubcomponentsCard variant="gridItem" />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>

        <EntitySwitch>
          <EntitySwitch.Case if={isComponentType(['platform-service'])}>
            <ExtraContentTiles />
          </EntitySwitch.Case>
        </EntitySwitch>
      </Grid>
    </EntityLayout.Route>
  );
};
