import { TableColumn } from '@backstage/core-components';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';

export const defaultCatalogColumns: TableColumn<CatalogTableRow>[] = [
  CatalogTable.columns.createTitleColumn({ hidden: true }),
  CatalogTable.columns.createNameColumn({ defaultKind: 'Component' }),
  CatalogTable.columns.createSystemColumn(),
  CatalogTable.columns.createOwnerColumn(),
  CatalogTable.columns.createSpecTypeColumn(),
  CatalogTable.columns.createSpecLifecycleColumn(),
  CatalogTable.columns.createTagsColumn(),
];

export const audienceColumn: TableColumn<CatalogTableRow> = {
  title: 'Audience',
  field: 'entity.spec.audience',
  hidden: false,
  width: 'auto',
};

export const tierColumn: TableColumn<CatalogTableRow> = {
  title: 'Tier',
  field: 'entity.spec.tier',
  hidden: false,
  width: 'auto',
};

export const allCatalogColumns: TableColumn<CatalogTableRow>[] = [
  ...defaultCatalogColumns.slice(0, 5),
  audienceColumn,
  tierColumn,
  ...defaultCatalogColumns.slice(5),
];
