import { Config, ConfigReader } from '@backstage/config';
import { MarkdownContent, Progress } from '@backstage/core-components';
import { useApi, useApp } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import OpenInNew from '@material-ui/icons/OpenInNew';
import React, { useCallback, useEffect } from 'react';
import { useAsyncFn } from 'react-use';

import { githubMediaApiRef } from '../../../apiClients/ghMedia';

const useStyles = makeStyles({
  cardActions: {
    justifyContent: 'flex-start',
  },
  noContent: {
    fontStyle: 'italic',
  },
  contentWrapper: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
});

type CardProps = {
  title: string;
  href?: string;
  buttonText?: string;
  docsContent?: string;
  buttonIconKey?: string;
};

const ContentTileButton = ({
  buttonText,
  buttonIconKey,
  href,
}: Omit<CardProps, 'title' | 'docsContent'>) => {
  const classes = useStyles();
  const app = useApp();

  if (!href || (!buttonText && !buttonIconKey)) {
    return null;
  }

  const ButtonIcon = app.getSystemIcon(buttonIconKey ?? '');

  if (!buttonText) {
    return (
      <CardActions className={classes.cardActions}>
        <Link href={href} target="_blank">
          <IconButton color="primary">
            {ButtonIcon ? (
              <ButtonIcon fontSize="large" />
            ) : (
              <OpenInNew fontSize="large" />
            )}
          </IconButton>
        </Link>
      </CardActions>
    );
  }

  return (
    <CardActions className={classes.cardActions}>
      <Button
        href={href}
        startIcon={ButtonIcon && <ButtonIcon fontSize="small" />}
        color="primary"
        variant="contained"
        target="_blank"
      >
        {buttonText}
      </Button>
    </CardActions>
  );
};

export const ExtraContentTiles = () => {
  const classes = useStyles();
  const githubMediaApi = useApi(githubMediaApiRef);
  const { entity } = useEntity();

  const getContentTileData = useCallback(
    async (contentTileConfig: Config): Promise<CardProps | null> => {
      try {
        const buttonIconKey = contentTileConfig.getOptionalString('buttonIcon');
        const docsPath =
          contentTileConfig.getOptionalString('relevantDocsLink');
        const docsContent = docsPath
          ? await githubMediaApi.getFile(docsPath).catch(() => undefined)
          : undefined;

        const templateCard: CardProps = {
          title: contentTileConfig.getString('title'),
          href: contentTileConfig.getOptionalString('buttonLink'),
          buttonText: contentTileConfig.getOptionalString('buttonText'),
          buttonIconKey,
          docsContent,
        };

        return templateCard;
      } catch {
        return null;
      }
    },
    [githubMediaApi],
  );

  const [state, getContentTilesFromConfig] = useAsyncFn<
    (contentTilesConfig: ConfigReader[]) => Promise<CardProps[]>
  >(
    async contentTilesConfig => {
      const cardsData: CardProps[] = [];
      for (const contentTileConfig of contentTilesConfig) {
        const templateCard = await getContentTileData(contentTileConfig);

        if (templateCard) {
          cardsData.push(templateCard);
        }
      }

      return cardsData ?? [];
    },
    [getContentTileData],
  );

  useEffect(() => {
    const specReader = new ConfigReader(entity.spec);
    const contentTilesConfig =
      specReader.getOptionalConfigArray('contentTiles') ?? [];

    if (contentTilesConfig.length > 0) {
      getContentTilesFromConfig(contentTilesConfig);
    }
  }, [getContentTilesFromConfig, entity.spec]);

  if (state.loading) {
    return (
      <Grid item xs={12}>
        <Progress />
      </Grid>
    );
  }

  return (
    <>
      {state.value?.map(
        ({ title, buttonIconKey, buttonText, docsContent, href }) => (
          <Grid item xs={12} key={title}>
            <Card>
              <CardHeader title={title} />
              <CardContent>
                {docsContent ? (
                  <MarkdownContent
                    content={docsContent}
                    className={classes.contentWrapper}
                  />
                ) : (
                  <Typography variant="body1" className={classes.noContent}>
                    No content available
                  </Typography>
                )}
              </CardContent>
              <ContentTileButton
                buttonText={buttonText}
                buttonIconKey={buttonIconKey}
                href={href}
              />
            </Card>
          </Grid>
        ),
      )}
    </>
  );
};
