import { StructuredMetadataTable } from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { isNil } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import { defineCheckStatus } from '../TemplatingEngine';
import { CheckResult } from '../types';
import { formatDate } from './helpers';
import { useGetNextRun } from './useGetNextRun';


export const CheckDialogContent = (props: {
  readonly result: CheckResult;
  errorMessage?: string;
}) => {
  const { result, errorMessage } = props;
  const timezone = 'GMT+1';

  const lastRunDate = `${formatDate(
    new Date(result.facts.timestamp as unknown as string),
  )} ${timezone}`;

  const { loading, error, data } = useGetNextRun(result.check.factIds[0]);

  const nextRunContent =
    loading || error || !data
      ? 'Unknown'
      : `${formatDate(new Date(data.nextRun))} ${timezone}`;

  const metadata: { [key: string]: any } = {
    'Check Status': defineCheckStatus(result),
    'Check type': (
      <>
        {result.event?.type} -{' '}
        <Typography
          variant="body2"
          color="primary"
          style={{ display: 'inline' }}
        >
          <Link
            target="_blank"
            to="/docs/default/component/backstage-documentation/3.-Advanced-topics/scorecards/2-checks/#available-check-types"
          >
            More Info
          </Link>
        </Typography>
      </>
    ),
    'Last Fact Update': (
      <>
        {lastRunDate} -{' '}
        <Typography
          variant="body2"
          color="primary"
          style={{ display: 'inline' }}
        >
          <Link
            target="_blank"
            to="/docs/default/component/backstage-documentation/1.-Common-usage/06-scorecards/#i-fixed-a-failing-check-but-i-dont-see-the-check-result-changing"
          >
            More Info
          </Link>
        </Typography>
      </>
    ),
    'Next Scheduled Fact Update': nextRunContent,
  };

  if (!isNil(errorMessage)) {
    metadata['Internal Errors'] = errorMessage;
  }

  return <StructuredMetadataTable metadata={metadata} dense />;
};
