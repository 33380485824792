import React from 'react';

import * as Constants from '../../constants';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';
import { LinkToWebsite, BrokenLink } from '../Common/common';

export function DatadogData(
  props: Readonly<{
    annotations: Record<string, string>;
    entityName: string;
  }>,
): React.JSX.Element {
  const { annotations, entityName } = props;

  const datadogService =
    annotations[Constants.DATADOG_SERVICE_ANNOTATION] ?? entityName;

  return (
    <LinkToWebsite
      url={`${Constants.DATADOG_URL}${datadogService}`}
      message={datadogService}
    >
      <ServiceAlignmentHelpDialog
        message="Links to the Datadog service associated with this service"
        field={`metadata.name OR metadata.annotation.${Constants.DATADOG_SERVICE_ANNOTATION}`}
        value={
          <LinkToWebsite
            url={`${Constants.DATADOG_URL}${datadogService}`}
            message={datadogService}
          />
        }
        fieldTitle="Datadog"
      >
        <BrokenLink app="Datadog service" />
      </ServiceAlignmentHelpDialog>
    </LinkToWebsite>
  );
}
