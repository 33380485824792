import { ComponentEntity, Entity } from "@backstage/catalog-model";
import { TableColumn } from "@backstage/core-components";
import { EntityTable } from "@backstage/plugin-catalog-react";

export const componentEntityColumns: TableColumn<ComponentEntity>[] = [
    EntityTable.columns.createEntityRefColumn({ defaultKind: 'component' }),
    EntityTable.columns.createOwnerColumn(),
    EntityTable.columns.createSpecTypeColumn(),
    EntityTable.columns.createSpecLifecycleColumn(),
    EntityTable.columns.createSystemColumn(),
    EntityTable.columns.createMetadataDescriptionColumn(),
  ];
export const componentEntityHelpLink: string =
'https://backstage.io/docs/features/software-catalog/descriptor-format#kind-component';

export const asComponentEntities = (entities: Entity[]): ComponentEntity[] =>
entities as ComponentEntity[];
