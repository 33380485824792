import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { EntityLayout } from '@backstage/plugin-catalog';
import React from 'react';

import { overviewContent } from './EntityContentTabs';
import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import { isAnnouncementsEnabled } from '../../../helpers/helpers';
import { useEntityLayoutRoutes } from '../../../hooks';

export const ComponentPage = () => {
  const configApi = useApi(configApiRef);
  const entityLayoutRoutes = useEntityLayoutRoutes();

  return (
    <EntityLayoutWrapper>
      {overviewContent({
        announcementEnabled: isAnnouncementsEnabled(configApi),
      })}

      {entityLayoutRoutes.map(route => (
        <EntityLayout.Route key={route.title} {...route} />
      ))}
    </EntityLayoutWrapper>
  );
};
