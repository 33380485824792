import { isNil } from 'lodash';
import React from 'react';

import * as Constants from '../../constants';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';
import { NotFound, LinkToWebsite, BrokenLink } from '../Common/common';

type ArgoCdDataProps = {
  annotations: Record<string, string>;
};

export function ArgoCdData({ annotations }: ArgoCdDataProps): React.JSX.Element {
  const field = `metadata.annotations.${Constants.ARGOCD_ANNOTATION_APP_SELECTOR}`;
  const fieldTitle = 'ArgoCD';
  const description = 'Links to the ArgoCD app associated with this service';

  const argocdApp = annotations[Constants.ARGOCD_ANNOTATION_APP_SELECTOR];

  return isNil(argocdApp) ? (
    <NotFound
      message={description}
      field={field}
      fieldTitle={fieldTitle}
      value={
        <LinkToWebsite
          url={Constants.LINK_TO_CATALOG_DOCS}
          color="secondary"
          message="Link your ArgoCD app now"
        />
      }
    />
  ) : (
    <LinkToWebsite
      url={`${Constants.ARGOCD_URL}?labels=${argocdApp}`}
      message={argocdApp}
      color={argocdApp.includes('app=') ? 'primary' : 'secondary'}
    >
      {argocdApp.includes('app=') ? (
        <ServiceAlignmentHelpDialog
          message={description}
          field={field}
          fieldTitle={fieldTitle}
          value={
            <LinkToWebsite
              url={`${Constants.ARGOCD_URL}?labels=${argocdApp}`}
              message={argocdApp}
            />
          }
        >
          <BrokenLink app="ArgoCd app" />
        </ServiceAlignmentHelpDialog>
      ) : (
        <ServiceAlignmentHelpDialog
          message={description}
          field={field}
          fieldTitle={fieldTitle}
          value={
            <LinkToWebsite
              url={Constants.LINK_TO_CATALOG_DOCS}
              color="secondary"
              message={Constants.ARGOCD_WRONG_FORMAT}
            />
          }
        />
      )}
    </LinkToWebsite>
  );
}
