import { Entity } from '@backstage/catalog-model';
import {
  DiscoveryApi,
  FetchApi,
  createApiRef,
} from '@backstage/core-plugin-api';
import { TechInsightCheck } from '@celonis/plugin-tech-insights-backend-module-jsonfc';

export type Group = {
  features: Record<string, any>;
  name: string;
  title: string;
  description: string;
  checks: TechInsightCheck[];
  maintainer: Entity | undefined;
};

export type TechinsightsGroupsApi = {
  getScorecards: () => Promise<Group[]>;
};

export const techinsightsGroupsApiRef = createApiRef<TechinsightsGroupsApi>({
  id: 'plugin.techinsightsgroups.service',
});

export class TechInsightsGroupsHttpApi implements TechinsightsGroupsApi {
  static create(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    return new TechInsightsGroupsHttpApi(discoveryApi, fetchApi);
  }

  constructor(private discoveryApi: DiscoveryApi, private fetchApi: FetchApi) {}

  async getScorecards() {
    const apiUrl = await this.discoveryApi.getBaseUrl(
      'tech-insights-groups-checks',
    );

    const response = await this.fetchApi
      .fetch(`${apiUrl}/groups`)
      .then(res => res.json() as Promise<{ groups: Group[] }>);

    return response.groups;
  }
}
