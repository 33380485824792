/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarOpenState,
} from '@backstage/core-components';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { createPermission } from '@backstage/plugin-permission-common';
import { usePermission } from '@backstage/plugin-permission-react';
import { Shortcuts } from '@backstage/plugin-shortcuts';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { Icon } from '@iconify/react';
import { Link, makeStyles } from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import BuildIcon from '@material-ui/icons/Build';
import CategoryIcon from '@material-ui/icons/Category';
import ExtensionIcon from '@material-ui/icons/Extension';
import HomeIcon from '@material-ui/icons/Home';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import MenuIcon from '@material-ui/icons/Menu';
import MapIcon from '@material-ui/icons/MyLocation';
import NotificationsActive from '@material-ui/icons/NotificationsActive';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SearchIcon from '@material-ui/icons/Search';
import React, { PropsWithChildren } from 'react';
import { NavLink } from 'react-router-dom';

import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import { getFlag } from '../../helpers/helpers';
import { SidebarSearchModal } from '../search';

// https://mui.com/material-ui/material-icons/?query=api
const adminViewPermission = createPermission({
  name: 'adminView',
  attributes: {},
});

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link
        component={NavLink}
        to="/"
        underline="none"
        className={classes.link}
        aria-label="Home"
      >
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

const AdminIcon = () => (
  <Icon icon="ic:sharp-admin-panel-settings" width={24} />
);

const ErrorEntitiesIcon = () => <Icon icon="material-symbols:error" />;

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const config = useApi(configApiRef);
  const permission = usePermission({ permission: adminViewPermission });

  return (
    <SidebarPage>
      <Sidebar>
        <SidebarLogo />

        {getFlag(config, 'search') && (
          <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
            <SidebarSearchModal />
          </SidebarGroup>
        )}

        <SidebarDivider />
        <SidebarGroup label="Menu" icon={<MenuIcon />}>
          {/* Global nav, not org-specific */}
          <SidebarItem icon={HomeIcon} to="/" text="Home" />
          {getFlag(config, 'catalog') && (
            <>
              <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
              <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
            </>
          )}

          {getFlag(config, 'playlist') && (
            <SidebarItem
              icon={PlaylistPlayIcon}
              to="playlist"
              text="Playlists"
            />
          )}

          {getFlag(config, 'techdocs') && (
            <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
          )}

          {getFlag(config, 'announcements') && (
            <SidebarItem
              icon={RecordVoiceOverIcon}
              to="announcements"
              text="Announcements"
            />
          )}

          {getFlag(config, 'scaffolder') && (
            <SidebarItem
              icon={CreateComponentIcon}
              to="create"
              text="Create..."
            />
          )}

          {/* End global nav */}
          <SidebarDivider />

          <SidebarScrollWrapper>
            {getFlag(config, 'orgChart') && (
              <SidebarItem
                icon={AccountTreeIcon}
                to="org-graph"
                text="Org View"
              />
            )}

            {getFlag(config, 'tech-radar') && (
              <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
            )}

            {getFlag(config, 'toolbox') && (
              <SidebarItem icon={BuildIcon} to="toolbox" text="Toolbox" />
            )}

            {getFlag(config, 'opsgenie') && (
              <SidebarItem
                icon={NotificationsActive}
                to="opsgenie"
                text="Opsgenie"
              />
            )}
          </SidebarScrollWrapper>

          <SidebarDivider />
          <Shortcuts />
        </SidebarGroup>
        <SidebarSpace />
        <SidebarDivider />

        {permission.allowed && (
          <>
            <SidebarGroup
              label="Admin"
              icon={<UserSettingsSignInAvatar />}
              to="/admin"
            >
              <SidebarItem icon={AdminIcon} text="Admin">
                <SidebarSubmenu title="Admin Views">
                  <SidebarSubmenuItem
                    icon={CategoryIcon}
                    title="Catalog"
                    to="/admin/catalog"
                  />
                  <SidebarSubmenuItem
                    icon={ErrorEntitiesIcon}
                    title="Unprocessed"
                    to="/admin/catalog-unprocessed-entities"
                  />
                </SidebarSubmenu>
              </SidebarItem>
            </SidebarGroup>
            <SidebarDivider />
          </>
        )}
        <SidebarGroup
          label="Settings"
          icon={<UserSettingsSignInAvatar />}
          to="/settings"
        >
          <SidebarSettings />
        </SidebarGroup>
      </Sidebar>
      {children}
    </SidebarPage>
  );
};
