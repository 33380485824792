import { EmptyState, LinkButton } from '@backstage/core-components';
import React from 'react';

export const ScorecardPageEmpty = () => {
  const linkToDocs =
    '/docs/default/component/backstage-documentation/3.-Advanced-topics/catalog-info-file/1-construct-catalog-info-file';
  return (
    <EmptyState
      missing="data"
      title="No Scorecards Available"
      description="No check results available for this entity. Please check if any facts exists. Learn about scorecards"
      action={
        <LinkButton variant="contained" color="primary" to={linkToDocs}>
          Learn about Scorecards
        </LinkButton>
      }
    />
  );
};
