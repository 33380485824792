import {
  Entity,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';
import {
  InfoCardVariants,
} from '@backstage/core-components';
import React from 'react';

import { asAPIEntities, apiEntityHelpLink, apiEntityColumns } from './presets';
import { RelatedEntitiesCard } from '../../RelatedEntitiesCard';

export interface DomainHasAPICardProps {
  variant?: InfoCardVariants;
  title?: string;
  entities?: Entity[];
}

export const DomainHasAPICard = (props: DomainHasAPICardProps) => {
  const { variant = 'gridItem', title = 'APIs', entities: systems } = props;

  return (
    <RelatedEntitiesCard
      variant={variant}
      title={title}
      entityKind="API"
      relationType={RELATION_HAS_PART}
      columns={apiEntityColumns}
      emptyMessage="No API is part of this domain"
      emptyHelpLink={apiEntityHelpLink}
      asRenderableEntities={asAPIEntities}
      entities={systems}
    />
  );
};
