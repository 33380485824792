import {
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';

/** @public */
export const celonisCatalogPlugin = createPlugin({
  id: 'celonis-catalog',
});

/** @public */
export const EntityLinksCard = celonisCatalogPlugin.provide(
  createComponentExtension({
    name: 'EntityLinksCard',
    component: {
      lazy: () =>
        import('./components/EntityLinksCard').then(m => m.EntityLinksCard),
    },
  }),
);
