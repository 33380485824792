import { IconComponent } from '@backstage/core-plugin-api';
import {
  SearchModalChildrenProps,
  SearchModalProvider,
  useSearchModal,
} from '@backstage/plugin-search';
import { Button, makeStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

import { SearchModal } from './SearchModal';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    // justifyContent: 'flex-start', // Align content to the left
    color: 'lightgray',
    width: '500px', // Set the button width
    maxWidth: '60vw',
    boxShadow: theme.shadows[1],
    padding: '8px 0',
    borderRadius: '50px',
    margin: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  customText: {
    fontSize: '1.2rem',
  },
}));

export type HomePageSearchModalProps = {
  icon?: IconComponent;
  children?: (props: SearchModalChildrenProps) => JSX.Element;
};

const HomePageSearchModalContent = (props: HomePageSearchModalProps) => {
  const { state, toggleModal } = useSearchModal();
  const Icon = props.icon ? props.icon : SearchIcon;

  const classes = useStyles();
  return (
    <>
      <Button
        variant="text"
        startIcon={<Icon />}
        onClick={toggleModal}
        className={`${classes.root} ${classes.customText}`}
      >
        Search
      </Button>
      <SearchModal
        {...state}
        toggleModal={toggleModal}
        children={props.children}
      />
    </>
  );
};

export const HomePageSearchModal = (props: HomePageSearchModalProps) => {
  return (
    <SearchModalProvider>
      <HomePageSearchModalContent {...props} />
    </SearchModalProvider>
  );
};
