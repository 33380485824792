import { Entity, RELATION_HAS_PART } from '@backstage/catalog-model';
import {
  InfoCard,
  InfoCardVariants,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import { Grid } from '@material-ui/core';
import React from 'react';

import { DomainHasAPICard } from './DomainHasApiCard';
import { DomainHasComponentCard } from './DomainHasComponentCard';
import { DomainHasResourceCard } from './DomainHasResourceCard';
import { LinkToCatalogFilteredButton } from './LinkToCatalogFilteredButton';

function getTargetRef(entity: Entity): string {
  const kind = entity.kind.toLowerCase();
  const namespace = entity.metadata.namespace ?? 'default';
  return `${kind}:${namespace}/${entity.metadata.name}`;
}

export interface DomainRelatedEntitiesPageProps {
  variant?: InfoCardVariants;
  title?: string;
}

export const DomainRelatedEntitiesPage = (
  props: DomainRelatedEntitiesPageProps,
) => {
  const { variant, title } = props;
  const { entity: domainEntity } = useEntity();

  const {
    entities: systems,
    loading,
    error,
  } = useRelatedEntities(domainEntity, {
    type: RELATION_HAS_PART,
    kind: 'System',
  });

  if (loading) {
    return (
      <InfoCard variant={variant} title={title}>
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    return (
      <InfoCard variant={variant} title={title}>
        <ResponseErrorPanel error={error} />
      </InfoCard>
    );
  }

  const targetRefs = systems?.map(e => getTargetRef(e)) ?? [];

  return (
    <Grid>
      {loading ? (
        <Progress />
      ) : (
        <>
          <Grid container>
            {/* Button aligned to the right */}
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="flex-end"
                style={{ paddingRight: '10px' }}
              >
                <LinkToCatalogFilteredButton entities={targetRefs} />
              </Grid>
            </Grid>

            {/* Left-aligned items */}
            <Grid item md={12}>
              <DomainHasComponentCard entities={systems} />
            </Grid>
            <Grid item md={12}>
              <DomainHasAPICard entities={systems} />
            </Grid>
            <Grid item md={12}>
              <DomainHasResourceCard entities={systems} />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};
