import { InfoCard, InfoCardVariants } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { Chip } from '@material-ui/core';
import React from 'react';

import { EntityAudienceEmptyState } from './emptyAudienceState';


/** @public */
export interface EntityAudienceCardProps {
  variant?: InfoCardVariants;
}

export const EntityAudienceCard = (props: EntityAudienceCardProps) => {
  const { variant } = props;
  const { entity } = useEntity();
  
  const audience = entity?.spec?.audience as string;

  return (
    <InfoCard title="Audience" variant={variant}>  
      { audience 
        ? (<Chip size="small" label={audience} />)
        : (<EntityAudienceEmptyState/>) 
      }
    </InfoCard>
  );
};