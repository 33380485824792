import { FieldExtensionComponentProps } from '@backstage/plugin-scaffolder-react';
import { TextField } from '@material-ui/core';
import { isNil } from 'lodash';
import React from 'react';

/*
 This is the actual component that will get rendered in the form
*/
export const UniqueEntityName = (
  props: FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    required,
    schema: {
      title = 'Entity Name',
      description = 'Unique name of the entity',
    },
    rawErrors,
    formData,
    uiSchema: { 'ui:autofocus': autoFocus } = {},
    idSchema,
    placeholder,
  } = props;
  return (
    <TextField
      id={idSchema?.$id}
      label={title}
      placeholder={placeholder}
      helperText={description}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => onChange(value)}
      margin="normal"
      error={!isNil(rawErrors) && rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus }}
    />
  );
};
