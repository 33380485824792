import {
  DiscoveryApi,
  FetchApi,
  createApiRef,
} from '@backstage/core-plugin-api';

export type GithubMediaApi = {
  getFile: (fileUrl: string) => Promise<string>;
};

export const githubMediaApiRef = createApiRef<GithubMediaApi>({
  id: 'plugin.github-media.service',
});

export class GithubMediaHttpApi implements GithubMediaApi {
  static create(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    return new GithubMediaHttpApi(discoveryApi, fetchApi);
  }

  constructor(private discoveryApi: DiscoveryApi, private fetchApi: FetchApi) {}

  async getFile(fileUrl: string): Promise<string> {
    const apiUrl = await this.discoveryApi.getBaseUrl('gh-media');

    const response = await this.fetchApi.fetch(
      `${apiUrl}/proxy?fileUrl=${fileUrl}`,
    );

    if (response.ok) {
      const contentType = response.headers.get('Content-Type');

      if (contentType?.includes('image')) {
        const imageBlob = await response.blob();
        return URL.createObjectURL(imageBlob);
      }

      const text = await response.text();
      return text;
    }

    const error = await response.json();
    throw new Error(`Failed to fetch file: ${error.message}`);
  }
}
