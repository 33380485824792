import { Content, Page } from '@backstage/core-components';
import {
  IconComponent,
  configApiRef,
  useApi,
  useApp,
} from '@backstage/core-plugin-api';
import {
  HomePageStarredEntities,
  HomePageToolkit,
} from '@backstage/plugin-home';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import {
  AnnouncementsCard,
  NewAnnouncementBanner,
} from '@celonis/backstage-plugin-announcements';
import { Icon } from '@iconify/react';
import { Grid } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';
import {
  HomePageRequestedReviewsCard,
  HomePageYourOpenPullRequestsCard,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import React from 'react';

import { WorldClock } from './BannerWorldClock';
import { HeaderCard } from './HeaderCard';
import { CelonisIcon } from '../../assets/icons/CustomIcons';
import { getFlag } from '../../helpers/helpers';
import { HomePageSearchModal } from '../search';


export const HomePage = () => {
  const config = useApi(configApiRef);
  const app = useApp();

  const iconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  const GithubIcon = iconResolver('github');

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <WorldClock />
        <Content>
          <Grid container justifyContent="center" spacing={6}>
            {getFlag(config, 'search') && (
              <Grid container item xs={12} alignItems="center" direction="row">
                <HomePageSearchModal />
              </Grid>
            )}

            {getFlag(config, 'announcements') && (
              <Grid container justifyContent="center" spacing={6}>
                <Grid item xs={12} md={10}>
                  <NewAnnouncementBanner />
                </Grid>
              </Grid>
            )}

            <Grid container item xs={12} spacing={6} alignItems="stretch">
              {getFlag(config, 'announcements') && (
                <Grid item xs={12} md={4}>
                  <AnnouncementsCard max={3} />
                </Grid>
              )}

              <Grid item xs={12} md={4}>
                <HomePageStarredEntities />
              </Grid>

              <Grid item xs={12} md={4}>
                <HomePageToolkit
                  tools={[
                    {
                      url: 'https://github.com/celonis',
                      label: 'GitHub',
                      icon: <GithubIcon />,
                    },
                    {
                      url: 'https://celonis.datadoghq.com',
                      label: 'Datadog',
                      icon: (
                        <Icon
                          icon="vscode-icons:file-type-datadog"
                          width={35}
                        />
                      ),
                    },
                    {
                      url: 'https://argocd.k8s.celonis.cloud',
                      label: 'Argo CD',
                      icon: <Icon icon="devicon:argocd" width={35} />,
                    },
                    {
                      url: 'https://account.activedirectory.windowsazure.com/applications/signin/52bb18f3-fc7b-44e2-ba6d-bc45449e4f6b?tenantId=b7ace95d-83d7-4723-85c7-b1575720809d',
                      label: 'Opsgenie',
                      icon: <Icon icon="logos:opsgenie" width={30} />,
                    },
                    {
                      url: 'https://celonis.atlassian.net/',
                      label: 'Jira',
                      icon: <Icon icon="logos:jira" width={30} />,
                    },
                    {
                      url: 'https://celocore.us-2.celonis.cloud',
                      label: 'Celocore',
                      icon: <CelonisIcon />,
                    },
                  ]}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={6} alignItems="stretch">
              <Grid item md={4} xs={12}>
                <HeaderCard
                  title="Get Started!"
                  label="get started"
                  description="Want to learn how to use Backstage? Start reading our Getting Started page!"
                  href="https://www.celonis.dev/docs/default/component/backstage-documentation/2-getting-started/"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <HeaderCard
                  title="Start Exploring!"
                  label="explore"
                  description="Discover all the amazing services Celonis has to offer by exploring our Catalog."
                  href="https://www.celonis.dev/catalog"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <HeaderCard
                  title="Celonis Architecture Docs"
                  label="gitbook"
                  description="Missing GitBook? Well, it's back and better than ever! Check it out under Celonis EMS Architecture."
                  href="https://www.celonis.dev/docs/default/component/documentation-architecture"
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} spacing={6} alignItems="stretch">
              <Grid item md={6} xs={12}>
                <HomePageRequestedReviewsCard />
              </Grid>
              <Grid item md={6} xs={12}>
                <HomePageYourOpenPullRequestsCard />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
