import { isEmpty, get } from 'lodash';
import { useMemo, useState, useEffect } from 'react';

import { CustomFilters } from '../../catalog/filters';

export function useSelectAnnotation(
  filters: CustomFilters,
  filterName: string,
  parameters?: string[] | string,
) {
  const queryParamAnnotations = useMemo(
    () => [parameters].flat().filter(Boolean) as string[],
    [parameters],
  );

  const [selectedAnnotations, setSelectedAnnotations] = useState(
    isEmpty(queryParamAnnotations)
      ? get(filters, [filterName, 'values'], [])
      : queryParamAnnotations,
  );

  useEffect(() => {
    if (queryParamAnnotations.length) {
      setSelectedAnnotations(queryParamAnnotations);
    }
  }, [queryParamAnnotations]);

  return {
    selectedAnnotations,
    setSelectedAnnotations,
  };
}
