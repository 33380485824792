import { EntityRefLinks } from '@backstage/plugin-catalog-react';
import React from 'react';

import { ServiceAlignmentHelpDialog } from '../../MissingDialog';

// Note: Name cannot be undefined. The entity won't get imported if that happens
export function OwnerData(props: Readonly<{ owners: string[] }>) {
  const { owners } = props;
  return (
    <>
      <EntityRefLinks entityRefs={owners} defaultKind="group" />
      <ServiceAlignmentHelpDialog
        message="Owner of this service. It should be defined in Celodrome"
        fieldTitle="Owner"
        field="spec.owner"
        value={<EntityRefLinks entityRefs={owners} defaultKind="group" />}
      />
    </>
  );
}
