import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { EntityAdrContent, isAdrAvailable } from '@backstage/plugin-adr';
import { EntityHasApisCard } from '@backstage/plugin-api-docs';
import { EntityLayout, EntitySwitch, isKind } from '@backstage/plugin-catalog';
import { EntityTeamPullRequestsContent } from '@backstage/plugin-github-pull-requests-board';
import { EntitySonarQubeCard } from '@backstage/plugin-sonarqube';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EntityArgoCDOverviewCard } from '@roadiehq/backstage-plugin-argo-cd';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import { EntityGithubPullRequestsContent } from '@roadiehq/backstage-plugin-github-pull-requests';
import React from 'react';

import { ComponentPage } from './ComponentPage';
import { DomainPage } from './DomainPage';
import {
  ciCdContent,
  codeInsightsContent,
  overviewContent,
  techDocsContent,
} from './EntityContentTabs';
import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import { GroupPage } from './GroupPage';
import { SystemPage } from './SystemPage';
import { getFlag, isAnnouncementsEnabled } from '../../../helpers/helpers';
import { ScoreCard } from '../../techInsights/Scorecard';
import { adrContentDecorator } from '../adrContentDecorator';
import { ApiPage } from './ApiPage';
import { ResourcePage } from './ResourcePage';
import { UserPage } from './UserPage';

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */
const DefaultEntityPage = () => {
  const configApi = useApi(configApiRef);

  return (
    /* Here we can set the default views to have on entities like databases and resources in general. To define  */
    <EntityLayoutWrapper>
      {overviewContent({
        announcementEnabled: isAnnouncementsEnabled(configApi),
      })}
      <EntityLayout.Route path="/github-actions" title="Github Actions">
        {ciCdContent}
      </EntityLayout.Route>

      {codeInsightsContent}

      <EntityLayout.Route
        if={entity => isAdrAvailable(entity) && getFlag(configApi, 'adr')}
        path="/adrs"
        title="ADRs"
      >
        <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/docs"
        title="Docs"
        if={() => getFlag(configApi, 'techdocs')}
      >
        {techDocsContent}
      </EntityLayout.Route>
    </EntityLayoutWrapper>
  );
};

export const neverPage = (
  <EntityLayoutWrapper>
    {/** Add any conditionaly rendered components cards here */}
    <ScoreCard />
    <EntitySonarQubeCard variant="gridItem" />
    <EntityGithubPullRequestsContent />
    <EntityGithubInsightsContent />
    <EntityTeamPullRequestsContent />
    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
    </EntityLayout.Route>
    <EntityArgoCDOverviewCard />
    <EntityTechdocsContent />
    <EntityHasApisCard variant="gridItem" />
  </EntityLayoutWrapper>
);
const initActionsPage = (
  <EntityLayoutWrapper>{ciCdContent}</EntityLayoutWrapper>
);

export const entityPage = (
  <EntitySwitch>
    {/* The following is a hack to initialize the github-actions component*/}
    <EntitySwitch.Case if={isKind('initAction')}>
      {initActionsPage}
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('component')}>
      <ComponentPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('resource')}>
      <ResourcePage />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('api')}>
      <ApiPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case
      if={isKind([
        'group',
        'GithubTeam',
        'MsgraphGroup',
        'WorkdayGroup',
        'ExtendedGroup',
      ])}
    >
      <GroupPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case
      if={isKind([
        'user',
        'GithubUser',
        'MsgraphUser',
        'WorkdayUser',
        'ExtendedUser',
      ])}
    >
      <UserPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('system')}>
      <SystemPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case if={isKind('domain')}>
      <DomainPage />
    </EntitySwitch.Case>
    {/* The following is a hack to show components conditionally. It forces initialise the API refs*/}
    <EntitySwitch.Case if={() => false}>{neverPage}</EntitySwitch.Case>
    <EntitySwitch.Case>
      <DefaultEntityPage />
    </EntitySwitch.Case>
  </EntitySwitch>
);
