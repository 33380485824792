import { parseEntityRef } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import { catalogPlugin } from '@backstage/plugin-catalog';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { Button } from '@material-ui/core';
import qs from 'qs';
import React from 'react';

function getQueryParams(systemsList: string[], kind?: string, type?: string) {
  const systems = systemsList.map(system =>
    humanizeEntityRef(parseEntityRef(system), {
      defaultKind: 'system',
    }),
  );
  const filters = {
    kind: kind ?? 'component',
    type: type ?? 'all',
    systems,
    user: 'all',
  };
  return qs.stringify({ filters }, { arrayFormat: 'repeat' });
}

export interface LinkToCatalogFilteredProps {
  entities: string[]; // Format kind:namespace/value
  defaultKind?: string;
  defaultType?: string;
}

export const LinkToCatalogFilteredButton = (
  props: LinkToCatalogFilteredProps,
) => {
  const { entities, defaultKind, defaultType } = props;
  const catalogLink = useRouteRef(catalogPlugin.routes.catalogIndex);

  return (
    <Button
      color="primary"
      variant="contained"
      href={`${catalogLink()}/?${getQueryParams(
        entities,
        defaultKind ?? 'component',
        defaultType ?? 'all',
      )}`}
    >
      Go to Advance Filtering
    </Button>
  );
};
