import { isNil } from 'lodash';
import React from 'react';

import * as Constants from '../../constants';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';
import { NotFound, LinkToWebsite, BrokenLink } from '../Common/common';

export function GithubData(props: {
  annotations: Record<string, string>;
}): React.JSX.Element {
  const { annotations } = props;
  const githubSlug = annotations[Constants.GITHUB_PROJECT_SLUG_ANNOTATION];
  const field = `metadata.annotations.${Constants.GITHUB_PROJECT_SLUG_ANNOTATION}`;
  const fieldTitle = 'Github Repo';

  const description = 'Links to the github repo associated with this service';

  return isNil(githubSlug) ? (
    <NotFound
      message={description}
      field={field}
      fieldTitle={fieldTitle}
      value={
        <LinkToWebsite
          url={Constants.LINK_TO_CATALOG_DOCS}
          color="secondary"
          message="Link your Github repo now"
        />
      }
    />
  ) : (
    <LinkToWebsite
      url={`https://github.com/${githubSlug}`}
      message={githubSlug}
    >
      <ServiceAlignmentHelpDialog
        message={description}
        field={field}
        fieldTitle={fieldTitle}
        value={
          <LinkToWebsite
            url={`https://github.com/${githubSlug}`}
            message={githubSlug}
          />
        }
      >
        <BrokenLink app="Github Repo" />
      </ServiceAlignmentHelpDialog>
    </LinkToWebsite>
  );
}
