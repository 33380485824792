import { Grid } from '@material-ui/core';
import { EntityArgoCDOverviewCard } from '@roadiehq/backstage-plugin-argo-cd';
import React from 'react';

import { ArgoCdImages, ArgoCdLink } from '../../ArgoCdComponents';

export const argoCdContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item md={12} xs={12}>
      <EntityArgoCDOverviewCard
        extraColumns={[
          {
            align: 'left',
            title: 'Environment',
            render: (data: any) => {
              return <>{data?.metadata?.labels?.env}</>;
            },
          },
          {
            align: 'left',
            title: 'Purpose',
            render: (data: any) => {
              return <>{data?.metadata?.labels?.purpose}</>;
            },
          },
          {
            align: 'left',
            title: 'Version',
            render: (data: any) => {
              return <ArgoCdImages images={data?.status?.summary?.images} />;
            },
          },
          {
            align: 'right',
            title: 'Go to Argo CD',
            render: (data: any) => {
              return <ArgoCdLink appName={data.metadata.name} />;
            },
          },
        ]}
      />
    </Grid>
  </Grid>
);
