import * as nunjucks from 'nunjucks';
import React from 'react';
import type { FC } from 'react';

import { RenderDescription } from '../TemplatingEngine';
import type { CheckProps, CustomCheckResult } from '../types';
import { CheckResultRenderer } from '../types';
import {
  StatusError,
  StatusOK,
  StatusWarningOptional,
  StatusWarning,
} from './Status';

const EVENT_TYPE = 'traffic-lights';

nunjucks.configure({
  autoescape: true,
});

export const TrafficLight: FC<CheckProps> = ({ checkResult }) => {
  if (checkResult.event.params.result === 'SUCCESS') {
    return <StatusOK />;
  }
  if (checkResult.event.params.result === 'WARN') {
    return <StatusWarning />;
  }

  // TODO: This is temporary solution to be solved by task: https://celonis.atlassian.net/browse/DX-919
  if (checkResult.event.params.result === 'WARN-SUCCESS') {
    return <StatusWarningOptional />;
  }
  return <StatusError />;
};

export const renderer: CheckResultRenderer = {
  type: EVENT_TYPE,
  component: checkResult => <TrafficLight checkResult={checkResult} />,
  description: checkResult => (
    <RenderDescription checkResult={checkResult as CustomCheckResult} />
  ),
};
