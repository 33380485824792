import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import React from 'react';

interface ScorecardToggleButtonProps {
  groupIndex: number;
  onToggleClick: any;
  successfullChecks: number;
  failedChecks: number;
  showAllChecks: boolean;
}

export const ScorecardToggleButton = (props: ScorecardToggleButtonProps) => {
  const handleToggleClick = () => {
    if (props.onToggleClick) {
      props.onToggleClick(props.groupIndex);
    }
  };

  const allChecksNumber = props.successfullChecks + props.failedChecks;
  if (props.failedChecks === 0) {
    return <></>;
  }

  const checks = `Show ${props.failedChecks}/${allChecksNumber} failed checks`;
  const label = { inputProps: { 'aria-label': 'show failed' } };

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch {...label} onChange={handleToggleClick} />}
        label={checks}
      />
    </FormGroup>
  );
};
