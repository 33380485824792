import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import {
  LightBox,
  ReportIssue,
  TextSize,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';
import React from 'react';

export const techDocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
      <TextSize />
      <LightBox />
      <Mermaid
        darkConfig={{ theme: 'dark' }}
        lightConfig={{ theme: 'forest' }}
      />
    </TechDocsAddons>
  </EntityTechdocsContent>
);
