import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { EntityBadgesDialog } from '@backstage/plugin-badges';
import { EntityLayout } from '@backstage/plugin-catalog';
import { EntityPlaylistDialog } from '@backstage/plugin-playlist';
import BadgeIcon from '@material-ui/icons/CallToAction';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import React, { ReactNode, useMemo, useState } from 'react';

import { getFlag } from '../../../helpers/helpers';

export const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);
  const [playlistDialogOpen, setPlaylistDialogOpen] = useState(false);
  const configApi = useApi(configApiRef);

  const extraMenuItems = useMemo(() => {
    const result = [];
    if (getFlag(configApi, 'badges')) {
      result.push({
        title: 'Badges',
        Icon: BadgeIcon,
        onClick: () => setBadgesDialogOpen(true),
      });
    }
    if (getFlag(configApi, 'playlist')) {
      result.push({
        title: 'Add to playlist',
        Icon: PlaylistAddIcon,
        onClick: () => setPlaylistDialogOpen(true),
      });
    }
    return result;
  }, [configApi]);

  return (
    <>
      <EntityLayout
        UNSTABLE_extraContextMenuItems={extraMenuItems}
        UNSTABLE_contextMenuOptions={{
          disableUnregister: 'visible',
        }}
      >
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
      <EntityPlaylistDialog
        open={playlistDialogOpen}
        onClose={() => setPlaylistDialogOpen(false)}
      />
    </>
  );
};
