import * as nunjucks from 'nunjucks';

import { renderer as booleanRenderer } from './CheckStatus/Boolean';
import { renderer as percentageRenderer } from './CheckStatus/Percentage';
import { renderer as trafficLightsRenderer } from './CheckStatus/TrafficLights';
import { findMatchingRenderer, renderCheckName } from './TemplatingEngine';
import type {
  CheckResultRenderer,
  CheckResult as OverridenCheckResult,
} from './types';

nunjucks.configure({
  autoescape: true,
});

const supportedRendererTypes = [
  trafficLightsRenderer,
  percentageRenderer,
  booleanRenderer,
];

export const eventBasedRenderer: CheckResultRenderer = {
  type: 'json-rules-engine',
  component(checkResult: OverridenCheckResult) {
    const renderer = findMatchingRenderer(checkResult, supportedRendererTypes);
    checkResult.check.name = renderCheckName(checkResult);
    return renderer.component(checkResult);
  },
  description(checkResult: OverridenCheckResult) {
    const renderer = findMatchingRenderer(checkResult, supportedRendererTypes);

    if (renderer.description) {
      return renderer.description(checkResult);
    }

    return '';
  },
};
