import { Entity } from '@backstage/catalog-model';
import {
  DefaultEntityFilters,
  EntityFilter,
} from '@backstage/plugin-catalog-react';
import { isNil } from 'lodash';

export type CustomFilters = DefaultEntityFilters & {
  audiences?: EntityAudienceFilter;
  systems?: EntitySystemFilter;
  missingAnnotation?: EntityAnnotationsFilter;
  annotations?: EntityAnnotationsFilter;
  tiers?: EntityTierFilter;
};

export class EntityAudienceFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const audience = entity.spec?.audience as string;
    return !isNil(audience) && this.values.includes(audience);
  }
  toQueryValue(): string[] {
    return this.values;
  }
}

export class EntitySystemFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const system = entity.spec?.system as string;
    return !isNil(system) && this.values.includes(system);
  }
  toQueryValue(): string[] {
    return this.values;
  }
}

export class EntityTierFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  filterEntity(entity: Entity): boolean {
    const tier = entity.spec?.tier?.toString();
    return !isNil(tier) && this.values.includes(tier);
  }
  toQueryValue(): string[] {
    return this.values;
  }
}

export class EntityAnnotationsFilter implements EntityFilter {
  constructor(readonly values: string[], readonly findMissing: boolean) {}
  filterEntity(entity: Entity): boolean {
    const annotations = Object.keys(entity.metadata.annotations ?? {});
    const checks: boolean[] = this.values.map(value =>
      annotations.includes(value),
    );

    return (
      checks !== undefined && checks.some(value => value === !this.findMissing)
    );
  }
  toQueryValue(): string[] {
    return this.values;
  }
}
