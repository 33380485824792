import { Chip, makeStyles } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';

import * as Constants from '../../constants';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';

const useStyles = makeStyles({
  displayLinebreak: {
    whiteSpace: 'pre-line',
  },
  chip: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const FedRAMPChip = ({ value }: { value: string }) => {
  const classes = useStyles();
  return value === 'true' ? (
    <Chip label="Yes" color="primary" className={classes.chip} />
  ) : (
    <Chip label="No" color="default" className={classes.chip} />
  );
};

export function FedRAMP(
  props: Readonly<{
    annotations: Record<string, string>;
  }>,
): React.JSX.Element {
  const classes = useStyles();
  const { annotations } = props;

  const annotationValue =
    annotations[Constants.FEDRAMP_ANNOTATION] ?? 'Not Set';

  return (
    <>
      <FedRAMPChip value={annotationValue} />
      <ServiceAlignmentHelpDialog
        message='FedRAMP annotation can be [ "true" | "false" ]. False has the same behavior as not having the annotation.'
        field={`metadata.annotation.${Constants.FEDRAMP_ANNOTATION}`}
        value={annotationValue}
        fieldTitle="FedRAMP Annotation"
      >
        <Alert severity="info">
          <AlertTitle>Enabling FedRAMP</AlertTitle>
          <div className={classes.displayLinebreak}>
            {Constants.FEDRAMP_MODAL_MESSAGE}
          </div>
        </Alert>
      </ServiceAlignmentHelpDialog>
    </>
  );
}
