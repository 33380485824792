import {
    DiscoveryApi,
    FetchApi,
    createApiRef,
} from '@backstage/core-plugin-api';



export type TechinsightsInfoApi = {
    getNextRun: (factRetrieverId: string) => Promise<{ nextRun: Date}>;
};

export const techInsightsInfoApiRef = createApiRef<TechinsightsInfoApi>({
    id: 'plugin.techinsightinfo.service',
});

export class TechInsightsInfoHttpApi implements TechinsightsInfoApi {
    static create(discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
        return new TechInsightsInfoHttpApi(discoveryApi, fetchApi);
    }

    constructor(private discoveryApi: DiscoveryApi, private fetchApi: FetchApi) { }

    async getNextRun(factRetrieverId: string) {
        const apiUrl = await this.discoveryApi.getBaseUrl(
            'techInsightsInfo',
        );

        const response = await this.fetchApi
            .fetch(`${apiUrl}/nextRun/${factRetrieverId}`)
            .then(res => res.json() as Promise<{nextRun: Date}>)
            .catch(error => {
                throw error;
            });

        return response;
    }
}
