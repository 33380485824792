import { pageTheme } from '@backstage/theme';

const themes = Object.keys(pageTheme);

export function makeTypeToTheme(type: string) {
  if (type.includes('get started')) {
    return themes[3];
  }

  if (type.includes('explore')) {
    return themes[1];
  }
  if (type.includes('gitbook')) {
    return themes[7];
  }


  return mapStringToCollection(themes, type);
}

export function mapStringToCollection<T = unknown>(
  collection: T[],
  value: string,
): T {
  const index = value.length % collection.length;
  return collection[index];
}
