import { isNil, last } from 'lodash';
import React from 'react';

import * as Constants from '../../constants';
import { ServiceAlignmentHelpDialog } from '../../MissingDialog';
import { LinkToWebsite, BrokenLink, NotFound } from '../Common/common';

const description =
  'Links to the folder in cfg-ibc associated with this service. If the cfg-ibc annotation is not present, the ArgoCD app value is re-used.';

export function CfgIBCData(props: {
  annotations: Record<string, string>;
}): React.JSX.Element {
  const { annotations } = props;

  const cfgIbcFolder = annotations[Constants.CFG_IBC_ANNOTATION];

  return isNil(cfgIbcFolder) ? (
    <CfgIbcMatchesArgo annotations={props.annotations} />
  ) : (
    <LinkToWebsite
      url={`${Constants.CFG_IBC_URL}${cfgIbcFolder}`}
      message={cfgIbcFolder}
    >
      <ServiceAlignmentHelpDialog
        message={description}
        field={`metadata.annotations.[${Constants.CFG_IBC_ANNOTATION}]`}
        fieldTitle="cfg-ibc folder"
        value={cfgIbcFolder}
      >
        <BrokenLink app="Cfg Ibc folder" />
      </ServiceAlignmentHelpDialog>
    </LinkToWebsite>
  );
}

// Use Argo
function CfgIbcMatchesArgo(props: { annotations: Record<string, string> }) {
  const { annotations } = props;
  const argocdApp = annotations[Constants.ARGOCD_ANNOTATION_APP_SELECTOR];

  return isNil(argocdApp) ? (
    <NotFound
      message={description}
      field={`metadata.annotations.${Constants.ARGOCD_ANNOTATION_APP_SELECTOR} and metadata.annotations.${Constants.CFG_IBC_ANNOTATION}`}
      fieldTitle="cfg-ibc folder"
      value={
        <LinkToWebsite
          url={Constants.LINK_TO_CATALOG_DOCS}
          color="secondary"
          message="Link your cfg-ibc folder now"
        />
      }
    />
  ) : (
    <LinkToWebsite
      url={`${Constants.CFG_IBC_URL}${last(argocdApp.split('=')) ?? ''}`}
      message={last(argocdApp.split('=')) ?? ''}
    >
      <ServiceAlignmentHelpDialog
        message={description}
        field={`metadata.annotations.${Constants.ARGOCD_ANNOTATION_APP_SELECTOR} is used over undefined metadata.annotations.${Constants.CFG_IBC_ANNOTATION}`}
        fieldTitle="cfg-ibc folder"
        value={argocdApp}
      >
        <BrokenLink app="Cfg Ibc folder" />
      </ServiceAlignmentHelpDialog>
    </LinkToWebsite>
  );
}
