import { createRoutableExtension } from '@backstage/core-plugin-api';
import {
  catalogGraphPlugin,
  catalogGraphRouteRef,
} from '@backstage/plugin-catalog-graph';

export const CatalogGraphPage = catalogGraphPlugin.provide(
  createRoutableExtension({
    name: 'CatalogGraphPage',
    component: () => import('./CatalogGraphPage').then(m => m.CatalogGraphPage),
    mountPoint: catalogGraphRouteRef,
  }),
);
