import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

import { UniqueEntityName } from './UniqueEntityName';
import { uniqueEntityNameValidation } from './validation';

export const UniqueEntityNameFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'UniqueEntityName',
    component: UniqueEntityName,
    validation: uniqueEntityNameValidation,
  }),
);
