import { Box, CircularProgress, Typography } from '@material-ui/core';
import { get, isNumber } from 'lodash';
import React from 'react';
import type { FC } from 'react';

import { usePercentageStyling } from '../Percentage/percentageStyling';
import { RenderDescription } from '../TemplatingEngine';
import type { CheckResult, CustomCheckResult, Event } from '../types';
import { CheckResultRenderer } from '../types';

const EVENT_TYPE = 'percentage';

export interface PercentageProps {
  checkResult: CheckResult<
    Event<
      typeof EVENT_TYPE,
      {
        total: number;
        factId: string;
        result: 'SUCCESS' | 'WARN' | 'ERROR';
        showPercentage?: boolean;
      }
    >
  >;
}

export const Percentage: FC<PercentageProps> = ({ checkResult }) => {
  const {
    total,
    factId,
    result,
    showPercentage = true,
  } = checkResult.event.params;
  const current = get(checkResult, ['facts', factId, 'value']);

  const styles = usePercentageStyling({ result, showPercentage });

  if (!isNumber(current)) {
    return <Box>N/A</Box>;
  }

  const progress = Math.round((current / total) * 100);
  const label = showPercentage ? `${progress}%` : `${current}/${total}`;

  return (
    <Box display="flex">
      <Box position="relative" display="inline-flex">
        <CircularProgress
          data-level={checkResult.event.params.result}
          variant="determinate"
          value={progress}
          className={styles.circleIndeterminate}
        />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            className={styles.label}
            variant="caption"
            component="div"
            color="textSecondary"
          >
            {label}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const renderer: CheckResultRenderer = {
  type: EVENT_TYPE,
  component: checkResult => (
    <Percentage checkResult={checkResult as PercentageProps['checkResult']} />
  ),
  description: checkResult => (
    <RenderDescription checkResult={checkResult as CustomCheckResult} />
  ),
};
