import { Entity } from '@backstage/catalog-model';
import { useEntityList } from '@backstage/plugin-catalog-react';
import React, { useEffect, useMemo } from 'react';

import {
  CustomFilters,
  EntityAnnotationsFilter,
} from '../../../catalog/filters';
import { AutocompleteFilter } from '../AutocompleteFilter';
import { EXCLUDED_ANNOTATIONS, MANDATORY_ANNOTATIONS } from '../constants';
import { useSelectAnnotation } from '../utils';

export interface EntityMissingAnnotationsPickerProps {
  hidden?: boolean;
}

export const EntityMissingAnnotationsPicker = (
  props: EntityMissingAnnotationsPickerProps,
) => {
  const { hidden } = props;

  const {
    backendEntities,
    filters,
    updateFilters,
    queryParameters: { missingAnnotation: parameters },
  } = useEntityList<CustomFilters>();

  const { selectedAnnotations, setSelectedAnnotations } = useSelectAnnotation(
    filters,
    'missingAnnotations',
    parameters,
  );

  const availableAnnotations = useMemo(
    () => [
      ...new Set(
        backendEntities
          .map((e: Entity) => Object.keys(e.metadata.annotations ?? {}))
          .flat()
          .filter(value => !EXCLUDED_ANNOTATIONS.includes(value)),
      ),
    ],
    [backendEntities],
  );

  const availableAnnotationsWithMandatory = [
    ...new Set([...availableAnnotations, ...MANDATORY_ANNOTATIONS]),
  ].sort((a, b) => a.localeCompare(b));

  useEffect(() => {
    updateFilters({
      missingAnnotation:
        selectedAnnotations.length && availableAnnotationsWithMandatory.length
          ? new EntityAnnotationsFilter(selectedAnnotations, true)
          : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnnotations.join(), availableAnnotationsWithMandatory.join()]); // Keep the eslint disable as the references for the values change. Using primitives keeps the values

  // Don't show "filter" if there are no values
  if (hidden || !availableAnnotationsWithMandatory.length) return null;
  return (
    <AutocompleteFilter
      title="Missing Annotations"
      tooltipDescription="Find which entities do not use a specific annotation"
      selectedData={selectedAnnotations}
      setSelectedData={setSelectedAnnotations}
      availableData={availableAnnotationsWithMandatory}
    />
  );
};
