import {
  EntitySwitch,
  hasRelationWarnings,
  EntityRelationWarning,
  isOrphan,
  EntityOrphanWarning,
  hasCatalogProcessingErrors,
  EntityProcessingErrorsPanel,
} from '@backstage/plugin-catalog';
import { Grid, Box, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';

export const EntityWarnings = () => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      <EntitySwitch>
        <EntitySwitch.Case if={hasRelationWarnings}>
          <Grid item xs={12}>
            <EntityRelationWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={isOrphan}>
          <Grid item xs={12}>
            <EntityOrphanWarning />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>

      <EntitySwitch>
        <EntitySwitch.Case if={hasCatalogProcessingErrors}>
          <Grid item xs={12}>
            <Box>
              <Alert severity="error">
                <Box>There are errors while processing this entity.</Box>
                {isExpanded ? (
                  <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    onClick={() => setExpanded(false)}
                  >
                    Hide errors
                  </Button>
                ) : (
                  <Button
                    variant="text"
                    size="small"
                    color="secondary"
                    onClick={() => setExpanded(true)}
                  >
                    Show errors
                  </Button>
                )}
              </Alert>
            </Box>
            {isExpanded && <EntityProcessingErrorsPanel />}
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    </>
  );
};
