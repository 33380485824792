import { useEntity } from '@backstage/plugin-catalog-react';
import { jsonRulesEngineCheckResultRenderer } from '@backstage/plugin-tech-insights';
import { get } from 'lodash';
import * as nunjucks from 'nunjucks';
import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';

import { CheckResult, CustomCheckResult, CheckResultRenderer } from './types';

export function setResultFromStatus(checkResult: CheckResult): CheckResult {
  const updatedCheckResult = checkResult;
  // TODO: This is temporary solution to be solved by task: https://celonis.atlassian.net/browse/DX-919
  if (
    updatedCheckResult.event.params?.result === 'SUCCESS' ||
    checkResult.event.params?.result === 'WARN-SUCCESS'
  ) {
    checkResult.result = true;
  }

  if (
    updatedCheckResult.event.params?.result === 'ERROR' ||
    checkResult.event.params?.result === 'WARN'
  ) {
    checkResult.result = false;
  }

  return checkResult;
}

export function defineCheckStatus(checkResult: CheckResult): string {
  let checkStatus = checkResult.result ? 'SUCCESS' : 'ERROR';

  // If a null value exist in the checkResult facts, set the result to false
  const responseFact = checkResult.facts
    ? Object.values(checkResult.facts).find(f => f.value === null)
    : undefined;

  // If a fact with a null value is found, set 'result' property to 'false'
  if (responseFact) {
    checkStatus = 'ERROR';
    if (checkResult.event.params?.result)
      checkResult.event.params.result = 'ERROR';

    return checkStatus;
  }

  if (checkResult.event.params?.result) {
    checkStatus = checkResult.event.params.result as string;
  }

  return checkStatus;
}

export function findMatchingRenderer(
  checkResult: CheckResult,
  supportedRenderers: CheckResultRenderer[],
) {
  return supportedRenderers.reduce(
    (
      selectedRenderer: CheckResultRenderer,
      currentRenderer: CheckResultRenderer,
    ) => {
      const isMatchingType =
        currentRenderer.type === get(checkResult, 'event.type');
      return isMatchingType ? currentRenderer : selectedRenderer;
    },
    jsonRulesEngineCheckResultRenderer as CheckResultRenderer,
  );
}

export const availableData = (checkResult: CustomCheckResult) => {
  return {
    entity: checkResult.entity ?? {},
    factsResult: checkResult.facts,
    check: checkResult.check,
  };
};

export const renderNunjucksTemplate = (template: string, context: any) => {
  try {
    return nunjucks.renderString(template, context);
  } catch (error) {
    return `Error while rendering template: ${error}`;
  }
};

interface RenderDescriptionProps {
  checkResult: CustomCheckResult;
}

export const RenderDescription: FC<RenderDescriptionProps> = ({
  checkResult,
}) => {
  const checkStatus = defineCheckStatus(checkResult);
  const entity = useEntity();
  checkResult.entity = entity.entity;

  return (
    <ReactMarkdown>
      {renderNunjucksTemplate(
        checkResult.check.metadata?.messages[checkStatus].message,
        availableData(checkResult),
      )}
    </ReactMarkdown>
  );
};

export function renderCheckName(checkResult: CustomCheckResult) {
  return renderNunjucksTemplate(
    checkResult.check.name,
    availableData(checkResult),
  );
}
