import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';

import { EntityLinkTypePicker } from './EntityLinkTypes';

export const EntityLinkTypePickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'EntityLinkTypePicker',
    component: EntityLinkTypePicker,
  }),
);
