import {
  Content,
  ContentHeader,
  PageWithHeader,
  SupportButton,
  TableColumn,
  TableProps,
} from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { CatalogTable, CatalogTableRow } from '@backstage/plugin-catalog';
import {
  CatalogFilterLayout,
  EntityLifecyclePicker,
  EntityListProvider,
  EntityProcessingStatusPicker,
  EntityOwnerPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListFilterKind,
  UserListPicker,
  EntityKindPicker,
} from '@backstage/plugin-catalog-react';
import React, { ReactNode } from 'react';

import { EntityAnnotationsPicker } from '../EntityAnnotationsPicker';
import { EntityAudiencePicker } from '../EntityAudiencePicker';
import { EntityMissingAnnotationsPicker } from '../EntityMissingAnnotationsPicker';
import { EntitySystemPicker } from '../EntitySystemPicker';
import { EntityTierPicker } from '../EntityTierPicker/entityTierPicker';

interface HeaderInfoProps {
  title: string;
  subtitle: string;
  themeId: string;
}

/**
 * Props for root catalog pages.
 *
 * @public
 */
export interface DefaultCatalogPageProps {
  initiallySelectedFilter?: UserListFilterKind;
  columns?: TableColumn<CatalogTableRow>[];
  actions?: TableProps<CatalogTableRow>['actions'];
  initialKind?: string;
  tableOptions?: TableProps<CatalogTableRow>['options'];
  emptyContent?: ReactNode;
  allowedKinds?: string[];
  headerInfo?: HeaderInfoProps;
}

export function DefaultCatalogPage(props: Readonly<DefaultCatalogPageProps>) {
  const {
    columns,
    actions,
    initiallySelectedFilter = 'owned',
    initialKind = 'component',
    tableOptions = {},
    emptyContent,
    allowedKinds,
    headerInfo,
  } = props;

  const orgName =
    useApi(configApiRef).getOptionalString('organization.name') ?? 'Backstage';

  const hideKinds = allowedKinds?.length === 1;

  const pageHeader: HeaderInfoProps = {
    title: headerInfo?.title ?? 'Catalog',
    themeId: headerInfo?.themeId ?? 'home',
    subtitle: headerInfo?.subtitle ?? 'Entity Explorer',
  };

  return (
    <PageWithHeader
      title={pageHeader.title}
      themeId={pageHeader.themeId}
      subtitle={`${orgName} ${pageHeader.subtitle}`}
    >
      <Content>
        <ContentHeader title="">
          <SupportButton>All your software catalog entities</SupportButton>
        </ContentHeader>
        <EntityListProvider>
          <CatalogFilterLayout>
            <CatalogFilterLayout.Filters>
              <EntityKindPicker
                initialFilter={initialKind}
                allowedKinds={allowedKinds}
                hidden={hideKinds}
              />
              <EntityTypePicker />
              <UserListPicker initialFilter={initiallySelectedFilter} />
              <EntityOwnerPicker />
              <EntityLifecyclePicker />
              <EntitySystemPicker />
              <EntityTagPicker />
              <EntityAudiencePicker />
              <EntityTierPicker />
              <EntityProcessingStatusPicker />
              <EntityAnnotationsPicker />
              <EntityMissingAnnotationsPicker />
            </CatalogFilterLayout.Filters>
            <CatalogFilterLayout.Content>
              <CatalogTable
                columns={columns}
                actions={actions}
                tableOptions={tableOptions}
                emptyContent={emptyContent}
              />
            </CatalogFilterLayout.Content>
          </CatalogFilterLayout>
        </EntityListProvider>
      </Content>
    </PageWithHeader>
  );
}
