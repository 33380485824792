import { createTranslationRef } from '@backstage/core-plugin-api/alpha';

export const scaffolderTranslationRef = createTranslationRef({
  id: 'scaffolder',
  messages: {
    fields: {
      teamMemberRolePicker: {
        title: 'Team Member Role',
        description: 'The team member belonging to the role',
      },
    },
  },
});
