import { EmptyState } from '@backstage/core-components';
import { EntityLayout, EntitySwitch } from '@backstage/plugin-catalog';
import {
  EntityGithubActionsContent,
  isGithubActionsAvailable,
} from '@backstage/plugin-github-actions';
import { Button } from '@material-ui/core';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import React from 'react';

export const codeInsightsContent = (
  <EntityLayout.Route path="/code-insights" title="Code Insights">
    <EntityGithubInsightsContent />
  </EntityLayout.Route>
);

export const ciCdContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
      <EntityGithubActionsContent view="cards" />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);
