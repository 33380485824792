import {
  RELATION_PART_OF,
  RELATION_HAS_PART,
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_PROVIDES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
} from '@backstage/catalog-model';
import {
  EntityLayout,
  EntityAboutCard,
  EntityHasSystemsCard,
} from '@backstage/plugin-catalog';
import {
  EntityCatalogGraphCard,
  Direction,
} from '@backstage/plugin-catalog-graph';
import { Grid } from '@material-ui/core';
import React from 'react';

import { GraphNode } from '../../catalogGraph/CatalogGraphNode';
import { DomainRelatedEntitiesPage } from '../DomainRelatedEntitiesPage';
import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import { EntityWarnings } from './EntityWarnings';

export const DomainPage = () => (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        <EntityWarnings />
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.LEFT_RIGHT}
        title="Domain Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional
        renderNode={GraphNode}
        maxDepth={2}
      />
    </EntityLayout.Route>
    <EntityLayout.Route path="/entities" title="Entities">
      <DomainRelatedEntitiesPage />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
