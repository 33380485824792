/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// FETCHED FROM https://github.com/backstage/backstage/blob/474cd71ab8af7b60d814de7493979daa1718d372/plugins/tech-insights/src/components/ScorecardsInfo/ScorecardInfo.tsx

import { InfoCard } from '@backstage/core-components';
import { EntityRefLink } from '@backstage/plugin-catalog-react';
import { makeStyles, Grid, Typography, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { isEmpty } from 'lodash';
import React, { ReactNode } from 'react';

import { ScorecardsList } from './ScorecardsList';
import { CheckResult, ScorecardGroupsContent } from './types';


const useStyles = makeStyles(theme => ({
  subheader: {
    fontWeight: 'bold',
    paddingLeft: theme.spacing(0.5),
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
}));

export const ScorecardInfo = (props: {
  checkResults: CheckResult[];
  title: string;
  actions: ReactNode;
  subheader: ReactNode;
  description?: string;
  group: ScorecardGroupsContent;
}) => {
  const { checkResults, title, description, group, actions, subheader } = props;
  const classes = useStyles();

  return (
    <InfoCard title={title} action={actions} subheader={subheader}>
      {description && (
        <Typography className={classes.subheader} variant="body1" gutterBottom>
          {description}
        </Typography>
      )}
      <Grid item xs={12}>
        {isEmpty(checkResults) ? (
          <Alert severity="warning">No checks have any data yet.</Alert>
        ) : (
          <ScorecardsList checkResults={checkResults} group={group} />
        )}
      </Grid>
      {!!group.maintainer && (
        <>
          <Divider className={classes.divider} />
          <Grid container item xs={12} alignItems="center">
            <Grid item>
              <Typography variant="body1">Maintainer:</Typography>
            </Grid>
            <Grid item>
              <EntityRefLink entityRef={group.maintainer} target="_blank" />
            </Grid>
          </Grid>
        </>
      )}
    </InfoCard>
  );
};
