import {
  RELATION_CHILD_OF,
  RELATION_MEMBER_OF,
} from '@backstage/catalog-model';
import { EntityLayout } from '@backstage/plugin-catalog';
import {
  EntityCatalogGraphCard,
  Direction,
} from '@backstage/plugin-catalog-graph';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import { Grid } from '@material-ui/core';
import React from 'react';

import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import { EntityWarnings } from './EntityWarnings';
import { GraphNode } from '../../catalogGraph/CatalogGraphNode';
import { UserProfileCard } from '../../org';

export const UserPage = () => (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={2}>
        <EntityWarnings />
        <Grid item xs={12} md={6}>
          <UserProfileCard variant="gridItem" showLinks />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/org-diagram" title="Org Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.LEFT_RIGHT}
        title="Org Diagram"
        height={700}
        relations={[RELATION_CHILD_OF, RELATION_MEMBER_OF]}
        kinds={['Group', 'User']}
        unidirectional
        maxDepth={1}
        renderNode={GraphNode}
      />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
